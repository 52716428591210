import React from 'react';
import './App.css';
import Combined from './Combined';
import PdfCv from './components/PdfCv';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import NotFound from './components/common/NotFound';

function App() {  
  
  return ( 
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Combined />} />
        <Route path="/:userId" element={<Combined />} />
        <Route path="/pdf-cv/:userId" element={<PdfCv />} />
        <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter> 
  );
}

export default App;
