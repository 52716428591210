import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export default function AlertMain(props) {
    
    const { alert, setAlert } = props
    
  return (
    <Snackbar
    open={alert.show}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    autoHideDuration={4000}
    onClose={() => {
      setAlert((prevState) => ({
        ...prevState,
        show: false,
      }))
    }
    }
  > 
    <Alert
      elevation={6}
      variant="filled"
      severity={alert.severity}
      onClose={() => {
        setAlert((prevState) => ({
          ...prevState,
          show: false,
        }))
      }
      }
    >
      {alert.message}
    </Alert>
  </Snackbar>
  )
}
