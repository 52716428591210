const Company = {
    compnayName: "PathinTech",
    mainUrl:"PathinTech.com",
    compnayNameCapitalize: "PathinTech",
    whiteLogoImg: "/images/PathintechlogoSVGWhite.svg",
    colorLogoImg: "/images/PathintechlogoSVG.svg",
    compnayEmail: "pathum@pathintech.com",
    noReplyEmail: "no-reply@pathintech.com",
    compnayAddress: "Colombo, Sri lanka",
    contactNumber: "(+94)70 112 8686",
    compnayWebsite: "https://pathintech.com/",
    enuriName: "Enuri Information Systems",
    enuriAddress: "Enuri Information Systems, 366/4, Habarakada, Homagama, Sri Lanka",
    facebookLink: "https://www.facebook.com/pathintech",
    instagramLink: "https://instagram.com/pathintech",
    youtubeLink: "https://www.youtube.com/c/PathumSimpson/",
    whatsappLink: "//api.whatsapp.com/send?phone=94701128686",
}

export default Company;