import React, { useState } from 'react';
import {  Button, Card, CardActions, CardContent, Divider, Grid, List, ListItem, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ExperienceInfoCard(props) {
  const displayWidthfold = useMediaQuery('(max-width:343px)');
  const { experience } = props?.digitalCVData
  const showValue = 2
  const [seeAllExperience, setSeeAllExperience] = useState(showValue)

  return (
    <Card
      sx={{
        ".css-1myhmif-MuiCardContent-root:last-child": {
          paddingBottom: {
            lg: "1rem",
            md: "1rem",
            sm: "1rem",
            xs: "0.5rem",
          },
        },
        
      }}
      style={{
        marginBottom: experience?.length > 1 ? 0 : "1rem"
      }}
    >
      <List sx={{
        backgroundColor: "#D1D2E9",
        paddingLeft: {
          lg: "1.375rem",
          md: "1.375rem",
          sm: "1.375rem",
          xs: displayWidthfold ? "0.5rem" : "1.375rem",
        }, paddingRight: {
          lg: "0.375rem",
          md: "0.375rem",
          sm: "0.375rem",
          xs: displayWidthfold ? "0.1rem" : "0.375rem",
        }
      }}>
        <ListItem
          secondaryAction={
            <Typography variant="body2" sx={{
              color: "#707070", textAlign: "right",
              fontSize: displayWidthfold ? "0.8rem" : "1rem"
            }}>
              {experience?.length ?? (0).toString()} Experiences
            </Typography>
          }
          disablePadding>
          <ListItemText>
            <Stack direction="row" useFlexGap alignItems="center" spacing={displayWidthfold ? "0.5rem" : "1.25rem"}>
              <BusinessCenterIcon sx={{ color: '#583EA7' }} />
              <Typography sx={{ color: "#232323", fontWeight: "bold", fontSize: displayWidthfold ? "0.8rem" : "1rem" }}>Experience</Typography>
            </Stack>
          </ListItemText>
        </ListItem>
      </List>
      <CardContent sx={{
        backgroundColor: 'white',
        paddingLeft: {
          lg: "1.375rem",
          md: "1.375rem",
          sm: "1.375rem",
          xs: "0.5rem",
        },
        paddingRight: {
          lg: "1.375rem",
          md: "1.375rem",
          sm: "1.375rem",
          xs: "0.5rem",
        },
        paddingTop: 0, paddingBottom: 0
      }}>
        <List>
          {experience && experience?.length > 0 ? experience?.slice(0, seeAllExperience).map((item, index) =>
            <dev key={index}>
              <ListItem alignItems="flex-start" sx={{ backgroundColor: "#F5F5F5", marginTop: "0.625rem", borderRadius: "0.3125rem" }}>
                <ListItemText
                  sx={{
                    marginLeft: {
                      lg: "0.5rem",
                      md: "0.5rem",
                      sm: "0rem",
                      xs: "0.5rem"
                    }
                  }}
                  primary={
                    <Typography
                      sx={{
                        display: 'inline', color: "#000000",
                        fontSize: {
                          lg: "1.125rem",
                          md: "1.125rem",
                          sm: "1.125rem",
                          xs: "1rem",
                        },
                        fontWeight: "600",
                        wordBreak: "break-all"
                      }}
                      component="span"
                      variant="body2"
                    >{item?.jobTitle}
                    </Typography>
                  }
                  secondary={
                    <Stack  direction="column" spacing={"0.5rem"}>
                      <Typography
                        sx={{
                          display: 'inline', color: "#707070", wordBreak: "break-all",
                          fontSize: {
                            lg: "0.875rem",
                            md: "0.875rem",
                            sm: "0.875rem",
                            xs: "0.875rem",
                          },
                        }}
                        component="span"
                        variant="body2"
                      >
                        {item?.companyName && <><span style={{ fontWeight: "600" }}>{item?.companyName}</span><br></br></>}
                        {item?.employmentType && <><span style={{ fontWeight: "regular" }}>{item?.employmentType}</span><br></br></>}
                        {item?.startDate && <span>{new Date(item?.startDate).toDateString().split(" ")[1]}{" "}
                        {new Date(item?.startDate).toDateString().split(" ")[3]} -{" "}
                        {item?.currentlyWorking ? "Present" :
                          (`${new Date(item?.endDate).toDateString().split(" ")[1]} 
                  ${new Date(item?.endDate).toDateString().split(" ")[3]}`)}
                          <br></br></span>}
                        
                        {item?.companyAddress && <span>{item?.companyAddress}<br></br></span>}
                      </Typography>
                      
                      {item?.description && <Typography
                        sx={{ display: 'inline', color: "#232323", fontSize: "0.875rem", wordBreak:"break-all" }}
                        component="span"
                        variant="body2"
                      >
                        {item?.description}
                      </Typography>}

                    </Stack>
                  }
                />
              </ListItem>
              {(index !== experience?.slice(0, seeAllExperience)?.length - 1) && <Divider component="li" />}
            </dev>) : (

          <Grid container sx={{ height: "10rem" }}>
            <Grid container item alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  No experiences available...
                </Typography>
              </Grid>
            </Grid>
          </Grid>

)}
        </List>
      </CardContent>
      {(experience && experience?.length !== 0 && experience?.length > seeAllExperience) ? (<>
        <Divider />
        <CardActions>
          <Grid container  >
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <Button size="small" variant="text" fullWidth
                  onClick={() => {
                    setSeeAllExperience(experience?.length)
                  }}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ textTransform: "none", color: "#707070", textAlign: "center", fontSize: "1rem" }}>
                  Show all {experience?.length} experiences
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </>) : (experience && experience?.length !== 0 && experience?.length > showValue) && (
        <>
          <Divider />
          <CardActions>
            <Grid container  >
              <Grid container item justifyContent="center" alignItems="center">
                <Grid item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button size="small" variant="text"
                    fullWidth
                    onClick={() => {
                      setSeeAllExperience(showValue)
                    }}
                    endIcon={<ArrowBackIcon />}
                    sx={{ textTransform: "none", color: "#707070", textAlign: "center", fontSize: "1rem" }}>
                    Show less experiences
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </>)
      }
    </Card>
  )
}

export default ExperienceInfoCard
