import { useCallback, useEffect, useState } from 'react';
import axios from './libs/axios'
import './App.css';
import { Card, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import PersonelInfoCard from './components/PersonelInfoCard';
import SkillsInfoCard from './components/SkillsInfoCard';
import ExperienceInfoCard from './components/ExperienceInfoCard';
import EducationInfoCard from './components/EducationInfoCard';
import FooterBanner from './components/FooterBanner';
import Footer from './components/common/Footer';
import { useParams, useLocation } from 'react-router-dom';
import { ReactComponent as Pathintechlogo } from '../src/images/pathintechlogoV2.svg';

function App() {
  const displayWidthForMobileFold = useMediaQuery('(max-width:343px)');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlParamsAccessToken = queryParams.get('token');
  const urlParamsJobId = queryParams.get('jobId');

  // company user reg url info
  const companyPanelUrl = process.env.REACT_APP_COMPANY_PANEL_URL;
  const encryptedCompanyId = queryParams.get('companyId');
  const encodedCompanyName = queryParams.get('companyName');
  const fullCompanyPanelRegUrl = `${companyPanelUrl}auth/register/?companyId=${encryptedCompanyId}&companyName=${encodedCompanyName}&comingFrom=digitalCv`;


  // top banner configs
  const alertThemeTextColorMain = "#ffff"
  const alertThemeColorBg = "#583EA7"
  const alertThemeBorderColorMain = "none"
  const alertThemeBorderSizeMain = "1px"

  const showSecondaryButton = false
  const alertThemeButtonTextColor = "#583EA7"

  const alertThemeButtonSuccessDefault = "#ffffff"
  const alertThemeButtonSuccessHover = "#F5F5F5"

  const alertThemeButtonDismiss = "#606060"
  
  const params = useParams();
  const { userId } = params;
  const [digitalCVData, setDigitalCVData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dismissAlert, setDismissAlert] = useState(false);

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "success",
  }); 
  
  const getDigitalCVData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(`/candidates/digital-cv/${userId}`, {
        urlParamsAccessToken,
        urlParamsJobId,
      });
      if (response.status === 200) {
        setDigitalCVData(response?.data);
        
        setTimeout(() => {
          setLoading(false);
        }, 2000);

      } else if (response.status === 404) {
        setAlert({
          show: true,
          message: "User not found",
          severity: "error",
        });

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      
      }
    } catch (error) {
      setAlert({
        show: true,
        message: error?.response?.data ?? "Server Error",
        severity: "error",
      });
      
      setTimeout(() => {
        setLoading(false);
      }, 2000);

    }
  }, [userId, urlParamsAccessToken, urlParamsJobId]);

  useEffect(() => {
    getDigitalCVData();
  }, [getDigitalCVData]);

  return (
    <>
      {!dismissAlert && !alert?.show && !loading &&(
        <Card sx={{
          zIndex: 1,
          backgroundColor: "#F3F2EF",
          position: "fixed",
          borderRadius:0,
          top: 0,
          width:"100%"
        }}
        >
          
          {encryptedCompanyId &&
            <FooterBanner
            dismissAlert={dismissAlert}
            alert={alert}
            loading={loading}
            alertThemeColorBg={alertThemeColorBg}
            alertThemeTextColorMain={alertThemeTextColorMain}
            alertThemeButtonSuccessDefault={alertThemeButtonSuccessDefault}
            alertThemeButtonSuccessHover={alertThemeButtonSuccessHover}
            alertThemeButtonDismiss={alertThemeButtonDismiss}
            alertThemeButtonTextColor={alertThemeButtonTextColor}
            fullCompanyPanelRegUrl={fullCompanyPanelRegUrl}
            showSecondaryButton={showSecondaryButton}
            alertThemeBorderColorMain={alertThemeBorderColorMain}
            alertThemeBorderSizeMain={alertThemeBorderSizeMain}
            setDismissAlert={setDismissAlert}
        />}
        </Card>)}
      
      {!alert?.show ? (<div>
        {userId ? (
          <div >
            {!loading ? (
              <Grid container
                pt={{
                  lg: !encryptedCompanyId && dismissAlert ? "1.5rem" :"7rem",
                  md: !encryptedCompanyId && dismissAlert ? "1.5rem" :"7rem",
                  sm: !encryptedCompanyId && dismissAlert ? "1.5rem" :"11rem",
                  xs: !encryptedCompanyId && dismissAlert ? "1.5rem" : displayWidthForMobileFold ? "14rem" : "13rem",
                }}
                pb={{
                  lg:"3.5rem",
                  md:"3.5rem",
                  sm:"3.5rem",
                  xs:"3rem",
                }}
                pl={{
                  lg: "5.625rem",
                  md: "3rem",
                  sm: "2rem",
                  xs: "2rem",
                }}
                pr={{
                  lg: "5.625rem",
                  md: "3rem",
                  sm: "2rem",
                  xs: "2rem",
                }}
                columnSpacing={1}
                rowSpacing={{
                  lg: 0,
                  md: 0,
                  sm: 0,
                  xs: 2,
                }}
                sx={{
                  backgroundColor: '#F3F2EF',
                  zIndex:0,
                }}
          >
            <Grid container item lg={3} md={4} sm={4} xs={12}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <PersonelInfoCard loading={loading} digitalCVData={digitalCVData} />
                <SkillsInfoCard loading={loading} digitalCVData={digitalCVData} />
              </Grid>
            </Grid>

                <Grid container item lg={9} md={8} sm={8} xs={12} rowSpacing={1.8}>
                  {digitalCVData?.experience?.length > 1 ?
                    (
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <ExperienceInfoCard loading={loading} digitalCVData={digitalCVData} />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} marginTop={"1rem"}>
                        <EducationInfoCard loading={loading} digitalCVData={digitalCVData} />
                      </Grid>
                    </Grid>
                      ) : (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                          <ExperienceInfoCard loading={loading} digitalCVData={digitalCVData}/>
                        <EducationInfoCard loading={loading} digitalCVData={digitalCVData} />
                      </Grid>)
                  }
                </Grid>
              </Grid>) : (
              <Grid container>
                <Grid container item direction="column" justifyContent={"center"} alignItems={"center"} sx={{ height: "100vh" }}>
                  <Grid item lg={"auto"} mb={2}>
                    <Pathintechlogo width={"12rem"} height={"100%"}/>
                  </Grid>
                  <Grid item lg={"auto"}>
                    <CircularProgress size="2.1rem" sx={{ color: "#3644F0" }} />
                  </Grid>
                </Grid>
              </Grid>

            )}
          </div>) : (
          <Grid container>
            <Grid container item direction="column" justifyContent={"center"} alignItems={"center"} sx={{ height: "100vh" }}>
              <Grid item lg={"auto"} mb={2}>
                <Pathintechlogo width={"12rem"} height={"100%"}/>
              </Grid>
              <Grid item lg={"auto"}>
                <Typography variant="h6" sx={{ color: "#000000", textTransform: "capitalize" }}>
                  User id not found!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>) : (
        <Grid container>
          <Grid container item direction="column" justifyContent={"center"} alignItems={"center"} sx={{ height: "100vh" }}>
            <Grid item lg={"auto"} mb={2}>
              <Pathintechlogo width={"12rem"} height={"100%"}/>
            </Grid>
            <Grid item lg={"auto"}>
              <Typography variant="h6" sx={{ color: "#000000", textTransform: "capitalize" }}>
                {alert?.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
          <Footer />

    </>
  );
}

export default App;
