import { Card, CardContent, Divider, Grid, List, ListItem, ListItemText, Stack, Typography, useMediaQuery, CardActions, Button, Tooltip } from '@mui/material'
import React,{useState} from 'react'
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SkillsInfoCard(props) {
  const displayWidthSmXs = useMediaQuery('(max-width:653px)');
  const displayWidthfold = useMediaQuery('(max-width:343px)');
  
  const showValue = 4
  const [seeAllSkills, setSeeAllSkills] = useState(showValue)

  const { skills } = props?.digitalCVData
  return (
    <Card
      sx={{
        ".css-1myhmif-MuiCardContent-root:last-child": {
          paddingBottom: {
            lg: "1rem",
            md: "1rem",
            sm: "1rem",
            xs: "0.5rem",
          },
        }
      }}
    >
      <List sx={{
        backgroundColor: "#D1D2E9",
        paddingLeft: {
          lg: "1.375rem",
          md: "1.375rem",
          sm: displayWidthSmXs ? "0.8rem" : "1.375rem",
          xs: displayWidthfold ? "0.5rem" : "1.375rem",
        },
        paddingRight: {
          lg: "0.375rem",
          md: "0.375rem",
          sm: "0.375rem",
          xs: displayWidthfold ? "0.1rem" : "0.375rem",
        },
      }}>
        <ListItem
          secondaryAction={
            <Typography variant="body2"
              sx={{
                color: "#707070",
                textAlign: "right",
                fontSize: displayWidthfold ? "0.8rem" : "1rem"
              }}>
              {skills?.length ?? (0).toString()} Skills
            </Typography>
          }
          disablePadding>
          <ListItemText>
            <Stack direction="row" useFlexGap alignItems="center"
              spacing={{
                lg: "1.25rem",
                md: "1.25rem",
                sm: "1.25rem",
                xs: displayWidthSmXs ? "0.5rem" : "1.25rem",
              }}
            >
              <StarIcon sx={{ color: '#583EA7' }} />
              <Typography
                sx={{
                  color: "#232323",
                  fontWeight: "bold",
                  fontSize: displayWidthfold ? "0.8rem" : "1rem",
                }}>Skills</Typography>
            </Stack>
          </ListItemText>
        </ListItem>
      </List>
      <CardContent
        sx={{
          backgroundColor: 'white',
          paddingLeft: {
            lg: "1.375rem",
            md: "1.375rem",
            sm: "1.375rem",
            xs: "0.5rem",
          },
          paddingRight: {
            lg: "1.375rem",
            md: "1.375rem",
            sm: "1.375rem",
            xs: "0.5rem",
          },
          paddingTop: 0, paddingBottom: 0
        }}>
         <List>
          {skills && skills?.length > 0 ? skills?.slice(0, seeAllSkills).map((item, index) =>
             <dev key={index}>
              <ListItem disablePadding
                sx={{
                  fontSize: "1rem",
                  padding: "0.75rem 0.9375rem 0.75rem 0.9375rem",
                  wordBreak: "break-all",
                  backgroundColor: "#F5F5F5",
                  marginTop: "0.625rem",
                  borderRadius: "0.3125rem"
                }}
                secondaryAction={item?.isMatchJobRequirement && <Tooltip title="Matched skill" placement="right-end"><CheckCircleIcon color='success'/></Tooltip> }
              >
               <ListItemText primary={item?.skill} sx={{wordBreak: "break-all"}} />
             </ListItem>
             {(index !== skills?.length - 1) && <Divider component="li" />}
           </dev>) : (

            <Grid container sx={{ height: "10rem" }}>
              <Grid container item alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textAlign: "center",
                    }}
                  >
                   No skills available...
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          )}
        </List>
      </CardContent>
      {(skills && skills?.length !== 0 && skills?.length > seeAllSkills) ? (<>
  <Divider />
  <CardActions>
    <Grid container  >
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}>
          <Button size="small" variant="text" fullWidth
            onClick={() => {
              setSeeAllSkills(skills?.length)
            }}
            endIcon={<ArrowForwardIcon />}
            sx={{ textTransform: "none", color: "#707070", textAlign: "center", fontSize: "1rem" }}>
            Show all {skills?.length} skills
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </CardActions>
</>) : (skills && skills?.length !== 0 && skills?.length > showValue) && (
  <>
    <Divider />
    <CardActions>
      <Grid container  >
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Button size="small" variant="text"
              fullWidth
              onClick={() => {
                setSeeAllSkills(showValue)
              }}
              endIcon={<ArrowBackIcon />}
              sx={{ textTransform: "none", color: "#707070", textAlign: "center", fontSize: "1rem" }}>
              Show less skills
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CardActions>
  </>)
}
    </Card>
  )
}

export default SkillsInfoCard;
