import { Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import CommonProps from '../common/Constant'

function Footer() {
  const socailIconSm = useMediaQuery("(max-width:600px)")

  const footerSocail = [
    {
      icon: "facebook",
      image: "/images/social_icons/facebookIcon.svg",
      url: CommonProps?.facebookLink
    },
    {
      icon: "instagram",
      image: "/images/social_icons/instagramIcon.svg",
      url: CommonProps?.instagramLink
    },
    {
      icon: "youtube",
      image: "/images/social_icons/youtubeIcon.svg",
      url: CommonProps?.youtubeLink
    },


  ]
  return (
    <Grid container
      sx={{ color: "white", backgroundColor: '#E8E7E4', padding: "2rem"}}
      lg={12} md={12} sm={12} xs={12} rowSpacing={2}>
      <Grid container item alignItems="center" justifyContent="center" columnSpacing={3}>
        <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>

          <Grid container item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} alignItems="center" justifyContent="center" columnSpacing={3}>
            {footerSocail.map((item, index) => {
              return (
                <Grid item key={index} lg={4} md={4} sm={4} xs={4}>
                  <a href={item?.url} target="_blank" rel="noreferrer">
                    <img src={item?.image} alt={item?.icon}
                      width={socailIconSm ? "25rem" : "100%"}
                      height={socailIconSm ? "25rem" : "100%"} />
                  </a>
                </Grid>
              )
            }
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container item alignItems="center" justifyContent="center" columnSpacing={3} rowSpacing={2}>

        <Grid item lg={11} md={11} sm={11} xs={11}>
          <Divider sx={{ borderBottomWidth: 1, backgroundColor: "white" }} />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body2" sx={{
            color: "#787878",
            textAlign: "center",
            fontSize: {
              lg: "0.9375rem",
              md: "0.9375rem",
              sm: "0.75rem",
              xs: "0.75rem",
            }
          }}>
            Powered by
            <span style={{ fontWeight: "600", color: "#787878"}} >
            <span style={{ textTransform: "capitalize" }}>
                {" "} {CommonProps?.mainUrl.split(".")[0]}</span>{"."}{CommonProps?.mainUrl.split(".")[1]}
                </span>
          </Typography>
        </Grid>

      </Grid>

    </Grid>
  )
}

export default Footer
