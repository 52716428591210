import React from "react";
import Pdf from "./pdf";
import { PDFViewer } from "@react-pdf/renderer";
import { useParams} from 'react-router-dom';

const PdfCv = () => {
  const params = useParams();
  const { userId } = params; 
  return (
    <div>
      <PDFViewer height={"620vh"} width={"100%"}>
        <Pdf userId={userId}/>
      </PDFViewer>
    </div>
  );
};

export default PdfCv;
