import React, { useState } from 'react';
import { useMediaQuery, Button, Card, CardActions, CardContent, Divider, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import SchoolIcon from '@mui/icons-material/School';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function EducationInfoCard(props) {
  const displayWidthfold = useMediaQuery('(max-width:343px)');
  const { education } = props?.digitalCVData
  const showValue = 2
  const [seeAllEducations, setSeeAllEducations] = useState(showValue)

  return (
    <Card
      sx={{
        ".css-1myhmif-MuiCardContent-root:last-child": {
          paddingBottom: {
            lg: "1rem",
            md: "1rem",
            sm: "1rem",
            xs: "0.5rem",
          },
        }
      }}
    >
      <List sx={{
        backgroundColor: "#D1D2E9",
        paddingLeft: displayWidthfold ? "0.5rem" : "1.375rem",
        paddingRight: displayWidthfold ? "0.1rem" : "0.375rem"
      }}>
        <ListItem
          secondaryAction={
            <Typography variant="body2" sx={{ color: "#707070", textAlign: "right", fontSize: displayWidthfold ? "0.8rem" : "1rem" }}>
              {education?.length ?? (0).toString()} Educations
            </Typography>
          }
          disablePadding>
          <ListItemText>
            <Stack direction="row" useFlexGap alignItems="center" spacing={displayWidthfold ? "0.5rem" : "1.25rem"}>
              <SchoolIcon sx={{ color: '#583EA7' }} />
              <Typography sx={{ color: "#232323", fontWeight: "bold", fontSize: displayWidthfold ? "0.8rem" : "1rem" }}>Education</Typography>
            </Stack>
          </ListItemText>
        </ListItem>
      </List>
      <CardContent sx={{
        backgroundColor: 'white',
        paddingLeft: {
          lg: "1.375rem",
          md: "1.375rem",
          sm: "1.375rem",
          xs: "0.5rem",
        },
        paddingRight: {
          lg: "1.375rem",
          md: "1.375rem",
          sm: "1.375rem",
          xs: "0.5rem",
        },
        paddingTop: 0, paddingBottom: 0
      }}>
        <List>
          {education && education?.length > 0 ? education?.slice(0, seeAllEducations).map((item, index) =>
            <dev key={index}>
              <ListItem alignItems="flex-start" sx={{ backgroundColor: "#F5F5F5", marginTop: "0.625rem", borderRadius: "0.3125rem" }}>
                <ListItemText
                  sx={{
                    marginLeft: {
                      lg: "0.5rem",
                      md: "0.5rem",
                      sm: "0rem",
                      xs: "0.5rem"
                    }
                  }}
                  primary={
                    <div>
                      {item?.school &&
                        <>
                          <Typography
                            sx={{
                              display: 'inline', color: "#232323",
                              fontSize: {
                                lg: "1.125rem",
                                md: "1.125rem",
                                sm: "1.125rem",
                                xs: "1rem",
                              },
                              fontWeight: "600",
                              wordBreak: "break-all"
                            }}
                            component="span"
                            variant="body2"
                          >{item?.school}
                          </Typography>
                          <br></br>
                        </>
                      }

                      {item?.category && <>
                        <Typography
                          sx={{
                            display: 'inline',
                            color: "#232323",
                            fontSize: "0.875rem",
                            textTransform: "capitalize",
                            wordBreak: "break-all"
                          }}
                          component="span"
                          variant="body2"
                        >
                          {item?.category}
                        </Typography><br></br>
                      </>}
                      {item?.description &&
                        <>
                        <Typography
                          sx={{ display: 'inline', color: "#232323", fontSize: "0.875rem", wordBreak: "break-all"}}
                          component="span"
                          variant="body2"
                        >
                          {item?.description}
                        </Typography>
                      </>}
                    </div>
                  }
                />
              </ListItem>
              {(index !== education?.slice(0, seeAllEducations)?.length - 1) && <Divider component="li" />}
            </dev>) : (

            <Grid container sx={{ height: "10rem" }}>
              <Grid container item alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    No educations available...
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          )}
        </List>
      </CardContent>
      {(education && education?.length !== 0 && education?.length > seeAllEducations) ? (<>
        <Divider />
        <CardActions>
          <Grid container  >
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                <Button size="small" variant="text" fullWidth
                  onClick={() => {
                    setSeeAllEducations(education?.length)
                  }}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ textTransform: "none", color: "#707070", textAlign: "center", fontSize: "1rem" }}>
                  Show all {education?.length} educations
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </>) : (education && education?.length !== 0 && education?.length > showValue) && (
        <>
          <Divider />
          <CardActions>
            <Grid container  >
              <Grid container item justifyContent="center" alignItems="center">
                <Grid item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button size="small" variant="text"
                    fullWidth
                    onClick={() => {
                      setSeeAllEducations(showValue)
                    }}
                    endIcon={<ArrowBackIcon />}
                    sx={{ textTransform: "none", color: "#707070", textAlign: "center", fontSize: "1rem" }}>
                    Show less educations
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </>)
      }
    </Card>
  )
}

export default EducationInfoCard
