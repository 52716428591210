import React from 'react';
import { Button, Grid } from '@mui/material';

function FooterBanner(props) {
    const { alertThemeTextColorMain,
        alertThemeButtonSuccessDefault, alertThemeButtonSuccessHover
      , alertThemeButtonDismiss, alertThemeBorderColorMain, showSecondaryButton,
      alertThemeButtonTextColor, setDismissAlert, alertThemeColorBg, fullCompanyPanelRegUrl,
      alertThemeBorderSizeMain} = props;
  return (
    <Grid container lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} 
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: alertThemeColorBg,
        border: alertThemeBorderColorMain === "none" ? 0 : `${alertThemeBorderSizeMain} solid ${alertThemeBorderColorMain}`,
        paddingBottom: {
          lg:"0.8rem",           
          md:"0.8rem",          
          sm:"0.8rem",          
          xs:"1rem"
        },
        paddingTop: {
          lg:"0.8rem",           
          md:"0.8rem",          
          sm:"0.8rem",          
          xs:"1rem"
        },
      }}>

          <Grid container item lg={10} md={10} sm={12} xs={12}
            alignItems="center"
            justifyContent="center"
          >

            <Grid item lg={12} md={12} sm={12} xs={12}
              sx={{
                fontSize: "0.9rem",
                wordBreak: "break-word",
                paddingBottom: "0.5rem",
                paddingLeft:{
                  lg:"3rem",                  
                  md:"3rem",                  
                  sm:"3rem",                  
                  xs:"1rem",
                },
                paddingRight:{
                  lg:"3rem",                  
                  md:"3rem",                  
                  sm:"3rem",                  
                  xs:"1rem",
                },
                textAlign: {
                  lg: "left",
                  md: "left",
                  sm: "center",
                  xs: "center"
                },
                fontWeight: "600",
                letterSpacing:"0.7",
                color: alertThemeTextColorMain,
              }}
            >
              <span style={{ fontSize: "1.3rem" }}>🌟</span>{" "}Elevate your company management experience with premium features and unlock a world of top IT talent. Register with PathinTech to view all applicants for this job posting!
            </Grid>
              </Grid>

              <Grid container item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} m={0.5}>
              <Button variant='contained'
              size="medium"
                sx={{
                  textTransform: "uppercase",
                  borderRadius:"0.313rem",
                  color: alertThemeButtonTextColor,
                  fontWeight: "600",
                  fontSize: "1rem",
                  backgroundColor: alertThemeButtonSuccessDefault,
                  '&:hover': {
                    backgroundColor: alertThemeButtonSuccessHover,
                  },
                }}
                onClick={() => {
                  window.open(fullCompanyPanelRegUrl, "_blank");
                }}>
                Register</Button>
            </Grid>

        {showSecondaryButton &&
          (<Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} m={0.5}>
              <Button variant='contained'
              size="medium"
                sx={{
                  textTransform: "uppercase",
                  borderRadius:"0.313rem",
                  color: alertThemeButtonTextColor,
                  fontWeight: "600",
                  fontSize: "1rem",
                  backgroundColor: alertThemeButtonDismiss,
                  '&:hover': {
                    backgroundColor: alertThemeButtonDismiss,
                  },
                }}
                onClick={() => setDismissAlert(true)}>
                Dismiss</Button>
            </Grid>)}
            </Grid>


        </Grid>
  )
}

export default FooterBanner
