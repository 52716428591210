import React, { useCallback, useState } from 'react'
import { Avatar, Card, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from '../../src/libs/axios';
import AlertMain from "../components/common/AlertMain"
import { useLocation } from 'react-router-dom';

function PersonelInfoCard(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlParamsJobId = queryParams.get('jobId');
  
  const displayWidthLgSm = useMediaQuery('(max-width:1341px)');
  const [alert, setAlert] = useState({
    show: false,
    severity: "",
    message: "",
  })

  const { firstName, lastName, email, mobile, profilePic, appliedJobArrObjId} = props?.digitalCVData
  const [clickToShow, setClickToShow] = useState({ email: email, mobile: mobile, hideShowBtn: false  });
  const [loading, setLoading] = useState();
  
  const handleShowClick = useCallback(async (e) => {
    if (clickToShow?.hideShowBtn===false) {
      if(appliedJobArrObjId){
      try {
        setLoading(true);
        const response = await axios.post(`/candidates/digital-cv/showPhoneEmail/${appliedJobArrObjId}`, {
          urlParamsJobId,
        });
        if (response.status === 200) {
          setClickToShow({ email: response?.data?.email, mobile: response?.data?.mobile, hideShowBtn: true })
          setLoading(false); 
        } else if (response.status === 404) { 
          setLoading(false);
          setAlert({
            show: true,
            severity: "error",
            message: response.status.message ?? "Something went wrong!",
          })
        }
     
      } catch (error) {
        setAlert({
          show: true,
          severity: "error",
          message: error?.response?.data?.message ?? "Something went wrong!",
        })
        setLoading(false);
      }}
    }

  } ,[clickToShow, appliedJobArrObjId, urlParamsJobId])

  

  return ( 
    <>
      <AlertMain alert={alert} setAlert={setAlert} /> 
    <Card sx={{ paddingTop: "2rem", marginBottom: "1rem", paddingLeft: "0.5rem", paddingRight: "0.5rem" }}> 
      <Grid container>
        <Grid container item alignItems={"center"} justifyContent={"center"}>
        <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
        <Avatar
          sx={{
            width: { lg: "8.5rem", md: "8.5rem", sm: "8rem", xs: "8rem" },
            height: { lg: "8.5rem", md: "8.5rem", sm: "8rem", xs: "8rem" },
            backgroundColor: "#F2F2F2",
            opacity: 1,
            position: "relative",
          }}
          src={profilePic?.image ?? ""}
        >
          {props?.loading ? (<CircularProgress size={"100%"} sx={{ color: "#c1b1f0", }} />
          ) : (<AccountCircleIcon
            sx={{
              color: "#583EA7",
              fontSize: { lg: "8rem", md: "8rem", sm: "10.8rem", xs: "9rem" },
              opacity: 1,
              width: { lg: "8rem", md: "8rem", sm: "8rem", xs: "8rem" },
              height: { lg: "8rem", md: "8rem", sm:"8rem", xs: "8rem" },
            }}
          />)}
        </Avatar>
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{
              color: "#232323",
              fontSize: {
                lg: displayWidthLgSm ? "1.575rem" :"1.875rem", md: "1.25rem", sm: "1.25rem", xs:"1.25rem"
              },
              wordBreak:"break-all",
              fontWeight: "bold", textAlign: "center"
            }}>{userName ?? "No Username"}</Typography>
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{
              color: "#707070",
              wordBreak:"break-all",
              fontSize: {
                lg: displayWidthLgSm ? "1.2625rem" :"1.5rem", md: "1rem", sm: "1rem", xs:"1rem"
              }, fontWeight: "bold", textAlign: "center", textTransform:"capitalize" }}>{`${firstName ?? ""}${" "}${lastName ?? ""}`}</Typography>
          </Grid>
          
          <Grid item lg={11} md={12} sm={12} xs={12}>
            <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem", borderBottomWidth: 2}} />
          </Grid>
          
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{
              color: "#707070",
              fontSize: {
                lg: displayWidthLgSm ? "1.01rem" : "1.15rem", md: "1rem", sm: "1rem", xs: "1rem"
              },
              wordBreak:"break-all",
              marginBottom: "1rem",
              textAlign: "center"
            }}>{clickToShow?.email ?? "Email not available"}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{
              color: "#707070", 
              wordBreak:"break-all",
              fontSize: {
                lg: displayWidthLgSm ? "1.01rem" :"1.15rem", md: "1rem", sm: "1rem", xs:"1rem"
              },
              textAlign: "center"
            }}>{clickToShow?.mobile ?? "Phone number not available"}</Typography>
          </Grid> 

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider sx={{ marginTop: "1.5rem",}} />
          </Grid>
          
          {!clickToShow?.hideShowBtn &&
            (<Grid item lg={12} md={12} sm={12} xs={12}>
            <LoadingButton
              loading={loading}
              variant="text"
              fullWidth
              size="small"
          onClick={handleShowClick}
              sx={{
                textTransform: "none", color: "#583EA7", textAlign: "center",
                fontSize: {
                  lg:  displayWidthLgSm ? "0.8rem" :"0.9rem", md: "0.6875rem", sm: "0.6875rem", xs:"0.6875rem"
                },
                paddingTop: "0.5rem", paddingBottom: "0.5rem"
              }}>
              Click here to show contact details</LoadingButton>
          </Grid>)}
        </Grid> 
      </Grid> 
      </Card>
      </>
  )
}

export default PersonelInfoCard
