import React, {
  useState, useEffect, useCallback,
  // useMemo
} from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { Line } from "@react-pdf/renderer";
import { Svg } from "@react-pdf/renderer";
import axios from "../libs/axios";
// import moment from "moment";
import ProfileImage from "../images/userIcon.png";

const styles = StyleSheet.create({
  infoDiv: {
    paddingBottom: 50,
    display: "flex",
    flexDirection: "column",
  },
  details: {
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  leftHeading: {
    fontSize: "14px",
    color: "#232323",
    fontWeight: 800,
    paddingBottom: 6,
    Family: "Roboto",
  },
  title: {
    fontSize: 12,
    color: "#232323",
    fontWeight: "bold",
    paddingBottom: 5,
  },
  text: {
    fontSize: 12,
    color: "#232323",
    fontWeight: "normal",
    paddingBottom: 13,
  },
  textMail: {
    fontSize: 12,
    textDecoration: "underline",
    color: "#232323",
    fontWeight: "normal",
    paddingBottom: 13,
  },
  name: {
    fontSize: 24,
    color: "#232323",
    fontWeight: 900,
    paddingBottom: 26,
    paddingTop: "33px",
    Family: `Roboto`,
  },
  qualification: {
    fontSize: 18,
    color: "#232323",
    fontWeight: "bold",
    paddingBottom: 6,
  },
  experienceTitle: {
    fontSize: 14,
    color: "#232323",
    fontWeight: "800",
    paddingBottom: 10,
    textTransform:"capitalize"
  },
  content: {
    fontSize: 12,
    color: "#232323",
    fontWeight: "normal",
    paddingBottom: 8,
  },
  contentEdu: {
    fontSize: 12,
    color: "#232323",
    fontWeight: "normal",
    paddingBottom: 8,
    lineHeight: 1.5,
  },
  list: {
    fontSize: 12,
    color: "#232323",
    fontWeight: "normal",
    paddingBottom: 5,
  },
  schools: {
    fontSize: 14,
    color: "#232323",
    fontWeight: "medium",
    paddingBottom: 5,
  },
});

const PDF = ({ userId }) => {
  const [candidate, setCandidate] = useState();
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [skills, setSkills] = useState([]);

  // const todayDate = new Date();
  // const todayISODate = new Date().toISOString();

  const getCandidate = useCallback(async () => {
    const { data } = await axios.get(
      `candidates/digital-cv/candidatePdfData/${userId}`
    );
    setCandidate(data);
    setExperience(data?.experience);
    setEducation(data?.education);
    setSkills(data?.skills);
  }, [userId]);
  // console log
//
  // const getYear =(currentlyWorking, endDate, startDate) => {
  //  const year = moment.duration(moment(currentlyWorking ? todayDate : new Date(endDate)).diff(new Date(startDate))).years()
  //  return year
  // }

  // const getMonth = (currentlyWorking, endDate, startDate) => {
  //   const month = moment.duration(moment(currentlyWorking ? todayDate : new Date(endDate)).diff(new Date(startDate))).months()
  //   return month
  // }

  // const getExperience = async () => {
  //   const { data } = await axios.get(
  //     `candidates/digital-cv/experience/${userId}`
  //   );
  //   setExperience(data?.experience);
  // };

  // const getEducation = async () => {
  //   const { data } = await axios.get(
  //     `candidates/digital-cv/education/${userId}`
  //   );

  //   setEducation(data?.education);
  // };

  // const getSkills = async () => {
  //   const { data } = await axios.get(`candidates/digital-cv/skills/${userId}`);
  //   setSkills(data?.skills);
  // };

  useEffect(() => {
    getCandidate();
    // eslint-disable-next-line
  }, []);

  return (
    <Document>
      <Page >
        {candidate && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                backgroundColor: "#D1D2E9",
                padding: 20,
                display: "flex",
                flexDirection: "column",
                width: "35%",
              }}
            >
              <div
                style={{
                  paddingBottom: 25,
                  width: "55%",
                  marginLeft: 30,
                  marginTop: 8,
                }}
              >
                {candidate?.profilePic?.status === "false" ? (
                  <Image src={`${candidate?.profilePic?.image}`} />
                ) : (
                  <Image src={ProfileImage} />
                )}
              </div>
              <div style={styles.infoDiv}>
                <Text style={styles.leftHeading}>Contact</Text>
                <Svg height="10" style={{ paddingBottom: "24px" }}>
                  <Line
                    x1="0"
                    y1="3"
                    x2="280"
                    y2="3"
                    strokeWidth={1}
                    stroke="#583EA7"
                  />
                </Svg>
                <Text style={styles.title}>Email:</Text>
                <Text style={styles.textMail}>{candidate?.email}</Text>
                <Text style={styles.title}>Phone number:</Text>
                <Text style={styles.text}>{candidate?.mobile}</Text>
              </div>
              {skills.length !== 0 ? (
                <div style={styles.infoDiv}>
                  <Text style={styles.leftHeading}>Core Skills</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="280"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>

                  {skills.map((item) => (
                    <Text key={item?.id} style={styles.text}>
                      {item?.skill}
                    </Text>
                  ))}
                </div>
              ) : (
                <>
                  <Text style={styles.leftHeading}>Core Skills</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="280"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>
                  <div style={styles.infoDiv}>
                    <Text style={styles.text}> Data not available!</Text>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                paddingLeft: 24,
                paddingRight: 25,
                display: "flex",
                flexDirection: "column",
                width: "65%",
              }}
            >
              <Text strokeWidth={23} style={styles.name}>
                {candidate?.firstName.charAt(0).toUpperCase() +
                  candidate?.firstName.substring(1)}{" "}
                {candidate?.lastName.charAt(0).toUpperCase() +
                  candidate?.lastName.substring(1)}
              </Text>
              {experience.length !== 0 ? (
                <div style={styles.infoDiv}>
                  <Text style={styles.qualification}>Experience</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="380"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>
                  {experience.map((item, index) => (
                    <View key={item?.id} style={styles.details} wrap={false}>
                      <Text style={styles.experienceTitle}>
                        {item?.companyName==="" || !item?.companyName ? "Company name not available" : item?.companyName }
                      </Text>
                      {/* {item?.startDate && <Text style={styles.content}>
                        {getYear(item?.currentlyWorking, item?.endDate, item?.startDate) !== 0 && (
                          `${getYear(item?.currentlyWorking, item?.endDate, item?.startDate)} ${getYear(item?.currentlyWorking, item?.endDate, item?.startDate)>1 ? "years" : "year"} `)}
                        {getMonth(item?.currentlyWorking, item?.endDate, item?.startDate) !== 0 && (
                          `${getMonth(item?.currentlyWorking, item?.endDate, item?.startDate)} ${getMonth(item?.currentlyWorking, item?.endDate, item?.startDate)>1 ? "months" : "month"} `)}
                      </Text>} */}

                      {item?.startDate && <Text style={styles.content}>{new Date(item?.startDate).toDateString().split(" ")[1]}{" "}
                        {new Date(item?.startDate).toDateString().split(" ")[3]} -{" "}
                        {item?.currentlyWorking ? "Present" :
                          (`${new Date(item?.endDate).toDateString().split(" ")[1]} ${new Date(item?.endDate).toDateString().split(" ")[3]}`)}
                        <br></br></Text>}
                      
                      <Text style={{fontSize: 12, color: "#232323", fontWeight: "normal", paddingBottom: 8, textTransform:"capitalize"}}>{item?.jobTitle}</Text>
                      {item?.description && <div style={styles.list}>
                        <View style={{ flexDirection: "row", marginBottom: 8 }}>
                          <Text style={{ fontSize: 12 }}>•</Text>
                          <Text style={{ paddingLeft: 3.5, lineHeight: 1.5 }}>
                            {item?.description}
                          </Text>
                        </View>
                      </div>}
                    </View>
                  ))}
                </div>
              ) : (
                <>
                  <Text style={styles.qualification}>Experience</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="380"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>
                  <div style={styles.infoDiv}>
                    <Text style={styles.text}> Data not available!</Text>
                  </div>
                </>
              )}
              {education.length !== 0 ? (
                <div style={styles.infoDiv}>
                  <Text style={styles.qualification}>Education</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="380"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>
                  {education.map((item) => (
                    <div style={styles.details}>
                      <Text key={item} style={{  fontSize: 14, color: "#232323", fontWeight: "medium", paddingBottom: 5, textTransform:"capitalize"}}>
                      {item?.school}
                      </Text>
                      <Text style={styles.contentEdu}>{item?.category}</Text>
                      <Text style={styles.contentEdu}>{item?.description}</Text>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <Text style={styles.qualification}>Education</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="380"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>
                  <div style={styles.infoDiv}>
                    <Text style={styles.text}> Data not available!</Text>
                  </div>
                </>
              )}
              {skills.length !== 0 ? (
                <div style={styles.infoDiv}>
                  <Text style={styles.qualification}>Skills</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="380"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>

                  <div style={{ paddingBottom: 0 }}>
                    {skills.map((item) => (
                      <Text key={item} style={styles.content}>
                        {item?.skill}
                      </Text>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <Text style={styles.qualification}>Skills</Text>
                  <Svg height="10" style={{ paddingBottom: "24px" }}>
                    <Line
                      x1="0"
                      y1="3"
                      x2="380"
                      y2="3"
                      strokeWidth={1}
                      stroke="#583EA7"
                    />
                  </Svg>
                  <div style={styles.infoDiv}>
                    <Text style={styles.text}> Data not available!</Text>
                  </div>
                </>
              )}
            </div>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default PDF;
