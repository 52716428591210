import React from 'react'; 
import { Grid, Typography } from '@mui/material';
import { ReactComponent as Pathintechlogo } from '../../images/pathintechlogoV2.svg';
import Footer from './Footer';

function NotFound() {
 
  return (<>
    <Grid container>
    <Grid container item direction="column" justifyContent={"center"} alignItems={"center"} sx={{ height: "100vh" }}>
      <Grid item lg={"auto"} mb={2}>
        <Pathintechlogo width={"12rem"}  height={"100%"}/>
      </Grid>
      <Grid item lg={"auto"}>
           <Typography variant="h6" sx={{ color: "#000000", textTransform:"capitalize" }}>
            404 not found!
             </Typography>
      </Grid>
    </Grid>
  </Grid>
  <Footer />
  </>
  );
}

export default NotFound;
